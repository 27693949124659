@import 'fonts';
@import 'variables';
@import 'breakpoints';

html,
body,
#__next,
main {
  margin: 0 !important;
  height: 100%;
  color: var(--color-primary-black);
}

* {
  box-sizing: border-box;
  font-family: $font-family-base;
  --menu-item-background-color-highlighted: var(--color-success);
}

.pageLayout {
  row-gap: 0 !important;
}

:root {
  --color-theme-background: var(--color-copper-light);
  --color-primary-black: var(--color-black-90);
  --color-secondary-link: var(--color-success-dark);
  --color-input-light: var(--color-success-light);
  --color-input: var(--color-success);
  --color-input-dark: var(--color-success-dark);
  --color-input-selected-text: var(--color-white);
  --color-keyword-free-event: #afe4de;

  /*hcrc styles*/
  --hcrc-color-link-show-all: var(--color-success-dark);
  --hcrc-color-hero-bg: var(--color-theme-background);
  --hcrc-color-archive-search-bg: var(--color-theme-background);
  --hcrc-color-archive-search-btn: var(--color-success);
  --hcrc-color-archive-search-btn-hover: var(--color-success-dark);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

::placeholder {
  font-size: var(--fontsize-body-m);
  color: var(--color-black-60);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: var(--fontsize-body-m);
  color: var(--color-black-60);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: var(--fontsize-body-m);
  color: var(--color-black-60);
}

#cookie-consent-language-selector-button {
  display: none;
}

.page h1,
.article-page header > h1 {
  font-size: var(--fontsize-heading-xl) !important;
  @include respond_below(s) {
    font-size: var(--fontsize-heading-xl-mobile) !important;
  }
}
